
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const formatTools = require("../../../lib/formatTools")
const webappsAPI = require("../../../lib/requestWebapps");


const ENTITYID="onscontact"


const searchFields = [
	{"label":"Contact", "dbfield": "onscontact_info", "type": "text", "filtertype": "text"},
	{"label":"Type", "dbfield": "onscontacttype_name", "type": "text", "filtertype": "text"},
];


const formFields = [
	{"label":"Contact", "field": "onscontact_info", "value": "", "input": "textbox", "mode": "unique", "info": "abc@sample.com, +639876543210, +63298765432, etc"},
	{"label":"Type", "field": "onscontacttype_id", "value": "", "input": "combo", "mode": "required", "options":[
		{"value":1,"display":"Mobile"},
		{"value":2,"display":"email"},
		{"value":5,"display":"Landline"}
	]},
];


const ContactinfoPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [documentid, setDocumentid] = useState(0);
	const [contacttypeid, setContacttypeid] = useState(0);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		if (pkid === 0) {
			params.onscontact_created=formatTools.getDateStr();
		}

		// Reformat to IDD if necessary
		if (params.hasOwnProperty("onscontact_info")) {
			var checkcontacttypeid = contacttypeid;
			if (params.hasOwnProperty("onscontacttype_id")) {
				checkcontacttypeid = params.onscontacttype_id;
			}
			if (checkcontacttypeid == 1 || checkcontacttypeid == 5) {
				if (params.onscontact_info.substring(0,1) === "0") {
					params.onscontact_info = "+63"+params.onscontact_info.substring(1);
				}
			}
		}

		//callback({"status":"Error", "message":JSON.stringify(params)}); return;

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, callback);
	}


	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (documentid > 0) {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		}
		return inputlist[idx];
	}


	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var newcontact = "";
		var newtypeid = 0;
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "onscontact_info") {
				newcontact = mainform[idx].value;
			} else if (mainform[idx].field === "onscontacttype_id") {
				if (!isNaN(parseInt(mainform[idx].value, 10))) {
					newtypeid = parseInt(mainform[idx].value, 10);
				}
			}
			idx++;
		}
		if (newtypeid < 1) {
			return {"status":"Error", "message":"Please indicate contact type"};
		} else if (newtypeid === 2) {
			// email
			if (formatTools.validateEmail(newcontact) === false) {
				return {"status":"Error", "message":"Invalid email"};
			}
		} else {
			// Phone # filtering/validation
			if (newcontact.substring(0,1) === "0") {
				newcontact = "+63"+newcontact.substring(1);
			}
			if (newcontact.substring(0,1) !== "+") {
				return {"status":"Error", "message":"Please use international format (e.g. +63xxxxx)"};
			} else {
				var tmpcontact = newcontact.substring(1).replace(/[^0-9]/g, '');
				if (tmpcontact !== newcontact.substring(1)) {
					return {"status":"Error", "message":"Please remove space, dash, etc"};
				} else if (tmpcontact.substring(0,1) === "0") {
					return {"status":"Error", "message":"Invalid contact"};
				} else if (tmpcontact.length < 7) {
					return {"status":"Error", "message":"Invalid contact"};
				}
			}
			if (newtypeid === 1) {
				// Mobile
				if (newcontact.substring(0,3) === "+63") {
					if (newcontact.length !== 13) {
						return {"status":"Error", "message":"Please use +639xxNNNNNNN format"};
					}
				}
			} else if (newtypeid === 5) {
				// landline
				if (newcontact.substring(0,3) === "+63") {
					if (newcontact.length !== 12) {
						return {"status":"Error", "message":"Please use +63xxxxx format"};
					}
				}
			}
		}

		return {"status":"OK"};
	}

	function customSetFormData(newformdata)
	{
		var newdocid = 0;
		var newcontacttypeid = 0;
		if (newformdata.hasOwnProperty(ENTITYID+"_id")) {
			if (newformdata[ENTITYID+"_id"]) {
				newdocid = newformdata[ENTITYID+"_id"];
			}
		}
		if (newformdata.hasOwnProperty("onscontacttype_id")) {
			if (null !== newformdata["onscontacttype_id"]) {
				newcontacttypeid = newformdata["onscontacttype_id"];
			}
		}
		setContacttypeid(newcontacttypeid);
		setDocumentid(newdocid);
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				location={location}
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["onscontact_info"]}
				searchFields={searchFields}
				editFields={[formFields]}

				customSubmit={customSubmit}
				customEditFieldInfo={customEditFieldInfo}
				customSetFormData={customSetFormData}
				customValidateValues={formValidateValues}

				token={token} />
		</Layout>

}


export default ContactinfoPage;
